// Colours CSS
:root {
	--white: #FFF;
	--black: #000;
	--primary-color: #C67EFC;
	--body-color: #033C81;
	--blue-shade: #9DBAE8;
	--dark-blue-bg: #122C47;
	--blue-light-text: #86B0E4;
	--black-1: #0E1316;
	--border-color: #8D0785;
	--brand-one: #1C3177;
	--border-color2: #3967A2;
	--path-bg: #18314C;
	--footer-bg: #F1F7FF;
	--icon-path: #24519F;
	--font-light-color: #DBEBFF;
	--footer-background: #C3D5E9;
	--service-block-color1: #CECBFF;
	--service-block-color2: #F5ECFF;
	--service-block-color3: #DFCFF1;
	--service-block-color4: #B3AFFF;
	--service-block-color5: #E6CFFF;
	--service-block-color6: #C5C2FF;
	--service-block-color7: #DFC3FF;
	--blue-text-color: #476CB7;
	--blue-text-color1: #0B3EB0;
	--border-color-light: #939CD1;
	--border-color-light1: #C0C9FD;
	--point-bgcolor1: #BBB8FC;
	--point-bgcolor2: #D7C0F3;
	--point-bgcolor3: #C39DF2;
	--point-bgcolor4: #C377FC;
	--point-bgcolor5: #D0B9EA;
	--point-bgcolor6: #F3C8E8;
	--point-bgcolor7: #9994FF;
	--point-bgcolor8: #7B88FF;
	--point-bgcolor9: #BC9EDD;
	--point-bgcolor10: #DB98CA;
	--point-bgcolor11: #D093FE;
	--point-bgcolor12: #F6EBFF;
	--point-bgcolor13: #E8F4FF;
	--point-bgcolor14: #DCDBFF;
	--point-bgcolor15: #F7EDFF;
	--pink-color1: #C47DFB;
	--border-color1: #C4D4F4;
	--border-color3: #D4D4D4;
	--link-color: #9E3AE8;
	--light-ass: #C7CAD3;
}

// Colours HEX
$white: var(--white);
$black: var(--black);
$primary-color: var(--primary-color);
$body-color: var(--body-color);
$blue-shade: var(--blue-shade);
$dark-blue-bg: var(--dark-blue-bg);
$blue-light-text: var(--blue-light-text);
$black-1: var(--black);
$border-color: var(--border-color);
$brand-one: var(--brand-one);
$border-color2: var(--border-color2);
$path-bg: var(--path-bg);
$footer-bg: var(--footer-bg);
$icon-path: var(--icon-path);
$font-light-color: var(--font-light-color);
$footer-background: var(--footer-background);
$service-block-color1: var(--service-block-color1);
$service-block-color2: var(--service-block-color2);
$service-block-color3: var(--service-block-color3);
$service-block-color4: var(--service-block-color4);
$service-block-color5: var(--service-block-color5);
$service-block-color6: var(--service-block-color6);
$service-block-color7: var(--service-block-color7);
$blue-text-color: var(--blue-text-color);
$border-color-light: var(--border-color-light);
$border-color-light1: var(--border-color-light1);
$point-bgcolor1: var(--point-bgcolor1);
$point-bgcolor2: var(--point-bgcolor2);
$point-bgcolor3: var(--point-bgcolor3);
$point-bgcolor4: var(--point-bgcolor4);
$point-bgcolor5: var(--point-bgcolor5);
$point-bgcolor6: var(--point-bgcolor6);
$point-bgcolor7: var(--point-bgcolor7);
$point-bgcolor8: var(--point-bgcolor8);
$point-bgcolor9: var(--point-bgcolor9);
$point-bgcolor10: var(--point-bgcolor10);
$point-bgcolor11: var(--point-bgcolor11);
$point-bgcolor12: var(--point-bgcolor12);
$point-bgcolor13: var(--point-bgcolor13);
$point-bgcolor14: var(--point-bgcolor14);
$point-bgcolor15: var(--point-bgcolor15);
$pink-color1: var(--pink-color1);
$border-color1: var(--border-color1);
$border-color3: var(--border-color3);
$link-color: var(--link-color);
$blue-text-color1: var(--blue-text-color1);
$light-ass: var(--light-ass);

// $gray-background: var(--gray-background);
// $border-color1: var(--border-color1);
// $dark-font-color: var(--dark-font-color);
// $base: var(--base);
// $base-two: var(--base-two);
// $button-color: var(--button-color);
// $green-color: var(--green-color);
// $dark-green-color: var(--dark-green-color);
// $icon-bg-color: var(--icon-bg-color);
// $image-bg-color: var(--image-bg-color); 
// $social-bg-color: var(--social-bg-color);
// $menu-border-color: var(--menu-border-color); 
// $header-menu-sticky-bg: var(--header-menu-sticky-bg);
// $brand-one-darken: var(--brand-one-darken);
// $brand-two: var(--brand-two); 
// $font-color-one: var(--font-color-one); 
// $menu-active: var(--menu-active);
// $highlight-color: var(--highlight-color);
// $filter-color: var(--filter-color);
// $filter-active-color: var(--filter-active-color);
// $yellow-color: var(--yellow-color);
// $button-active: var(--button-active);

// social media share links
$facebook: #6081C4;
$twitter: #81DAFF;
$linkdin: #1777B5;
$instagram: #F100A5;

.primary-color {
	color: $primary-color;
}
