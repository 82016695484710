@use "../abstracts/colors";
@use "../abstracts/functions";
@use "../abstracts/media-query";
@use "../abstracts/spacing";
@use "../abstracts/fonts";
@use "../abstracts/anchors";
@use "../abstracts/shadows";
@use "../abstracts/buttons";
@use "../abstracts/z-index";
@use "../abstracts/animations";
@use "../abstracts/border-radius";

.o-pricing {
	$o-pricing: &;

	position: relative;
	z-index: z-index.$on-top;
	background: rgb(255, 255, 255);
	background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(218, 231, 254, 1) 100%);

	&__spacing {
		padding: functions.torem(120px) 0 functions.torem(100px);
		@include media-query.breakpoint(smallg) {
			padding: functions.torem(146px) 0 functions.torem(80px);
		}
	}

	&__heading {
		@include fonts.font-size--top-heading;
		margin-bottom: functions.torem(20px);
		text-align: center;
		text-transform: fonts.$uppercase;
	}

	&__subheading {
		@include fonts.font-size--itemtitle;
		color: colors.$black;
		text-align: center;
		text-transform: fonts.$uppercase;
	}

	&__tabheader {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		margin: functions.torem(36px) 0 functions.torem(30px);
	}

	&__tabtn {
		@include fonts.font-size--itemtitle;
		margin: 0 functions.torem(5px);
		padding: functions.torem(9px) functions.torem(16px);
		color: colors.$black;
		text-align: center;
		background-color: transparent;
		border: functions.torem(1px) solid transparent;
		border-radius: border-radius.$border-radius--sbtn;

		&.active {
			border: functions.torem(1px) solid colors.$point-bgcolor4;
		}
	}

	&__content {
		transition: opacity 1s ease-in-out;
	}

	&__planwrapper {
		display: flex;
		@include media-query.breakpoint(smallg) {
			justify-content: center;
			align-items: flex-start;
			flex-wrap: wrap;
		}
	}

	&__planbox {
		position: relative;
		z-index: z-index.$on-top;
		width: 100%;
		max-width: 100%;
		padding: functions.torem(59px) functions.torem(30px) functions.torem(24px);
		background-color: colors.$white;
		border: functions.torem(1px) solid colors.$border-color1;
		border-radius: border-radius.$border-radius--box-12;
		box-shadow: 0 functions.torem(4px) functions.torem(4px) 0 rgba(194, 213, 249, .5);
		transition: all .5s ease-out;
		@include media-query.breakpoint(smallg) {
			max-width: functions.torem(232px);
			margin: 0 functions.torem(7px);
			padding: functions.torem(59px) functions.torem(10px) functions.torem(10px);
		}
		@include media-query.breakpoint(medium) {
			max-width: functions.torem(307px);
			padding: functions.torem(59px) functions.torem(30px) functions.torem(24px);
		}

		&--popular {
			&::before {
				content: "POPULAR";
				position: absolute;
				top: functions.torem(22px);
				left: 0;
				display: flex;
				width: functions.torem(78px);
				height: functions.torem(23px);
				justify-content: center;
				align-items: center;
				color: colors.$black;
				font-size: functions.torem(12px);
				text-transform: fonts.$uppercase;
				background-color: rgba(195, 119, 252, .19);
			}
		}

		&:hover {
			box-shadow: 0 functions.torem(10px) functions.torem(10px) 0 rgba(194, 213, 249, .7);
		}
	}

	&__planboxtop {
		width: 100%;
		padding-bottom: functions.torem(12px);
		text-align: center;
		border-bottom: functions.torem(1px) solid colors.$border-color3;
	}

	&__planame {
		@include fonts.font-size--normaldifferent;
		margin-bottom: functions.torem(15px);
		text-align: center;
	}

	&__plancost {
		@include fonts.font-size--cost;
		margin-bottom: functions.torem(2px);
		color: colors.$black;
	}

	&__plantip {
		@include fonts.font-size--itemtitle1;
		color: colors.$black;
	}

	&__planlist {
		padding-top: functions.torem(14px);
	}

	&__planitem {
		position: relative;
		@include fonts.font-size--itemtitle1;
		margin-bottom: functions.torem(16px);
		padding-left: functions.torem(30px);
		color: colors.$black;
		background: url(../../assets/images/icons/li-before.svg) no-repeat;
		background-position: left center;
		background-size: 20px;
	}

	&__planbtn {
		@include fonts.font-size--itemtitle;
		display: flex;
		// max-width: functions.torem(148px);
		width: 100%;
		height: functions.torem(40px);
		justify-content: center;
		align-items: center;
		margin: functions.torem(30px) auto 0;
		font-weight: fonts.$font-weight--bold;
		border-radius: border-radius.$border-radius--sbtn;
		@include media-query.breakpoint(medium) {
			width: auto;
		}
	}

	&__shape1 {
		position: absolute;
		bottom: 30%;
		left: 0;
		z-index: z-index.$background;
		width: functions.torem(85px);
	}

	&__shape2 {
		position: absolute;
		right: functions.torem(-10px);
		bottom: 5%;
		z-index: z-index.$background;
	}

	.splide__arrows {
		position: absolute;
		right: 0;
		bottom: functions.torem(-50px);
		left: 0;
		z-index: 2;
		width: functions.torem(150px);
		margin: auto;
	}
}
