@use "../abstracts/colors";
@use "../abstracts/functions";
@use "../abstracts/media-query";
@use "../abstracts/spacing";
@use "../abstracts/fonts";
@use "../abstracts/anchors";
@use "../abstracts/shadows";
@use "../abstracts/buttons";
@use "../abstracts/z-index";
@use "../abstracts/border-radius";

.o-modal {
	$modal: &;

	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: z-index.$high;
	display: none;
	background: rgba(0, 0, 0, .8);
	opacity: 0;
	transition: opacity .5s ease;

	&.show {
		display: block;
		opacity: 1;
	}
	

	&__wrapper {
		position: relative;

		top: 50%;
		right: 0;
		left: 0;
		width: 88%;
		max-width: functions.torem(600px);
		margin: auto;
		border-radius: border-radius.$border-radius--small;
		transform: translateY(-50%);

		&--lg {
			max-width: functions.torem(1024px);
		}

		&--md {
			max-width: functions.torem(767px);
		}

		&--small {
			max-width: functions.torem(600px);
		}
	}

	&__close {
		position: absolute;
		top: functions.torem(-30px);
		right: functions.torem(-20px);
		width: functions.torem(30px);
		height: functions.torem(30px);
		padding: functions.torem(3px);
		background-color: colors.$white;
		border-radius: 100%;
		cursor: pointer;
	}

	&__close-icon {
		max-width: 100%;
	}

	&__videopopup {
		position: relative;
		width: 100%;
		max-width: functions.torem(751px);
		height: functions.torem(300px);
		margin: auto;
		border-radius: border-radius.$border-radius--video;
		overflow: hidden;
		@include media-query.breakpoint(smallg) {
			height: functions.torem(423px);
		}
	}

	&__videopopupoverlay {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		background: rgb(128, 168, 232);
		background: linear-gradient(270deg, rgba(128, 168, 232, 1) 0%, rgba(128, 168, 232, .8) 30%, rgba(128, 168, 232, .4) 50%, rgba(255, 255, 255, 0) 100%);
	}

	&__videopopupimage {
		position: relative;
		left: 25%;
	}

	&__iframe {
		height: 100%;
		height: functions.torem(300px);
		border-radius: border-radius.$border-radius--video;
		@include media-query.breakpoint(smallg) {
			height: functions.torem(423px);
		}
	}

	&__video {
		height: 100%;
		height: functions.torem(300px);
		border-radius: border-radius.$border-radius--video;
		@include media-query.breakpoint(smallg) {
			height: functions.torem(423px);
		}
	}
}
