$background: -1;
$base: 0;
$on-top: 1;
$overlay: 2;
$takeover: 4;
$header: 5;
$modal: 6;
$high: 9;
$high1: 99;
$high2: 999;
$high3: 99999;
