@use "../abstracts/media-query";
@use "../abstracts/functions";
@use "../abstracts/colors";
@use "../abstracts/fonts";
@use "../abstracts/z-index";

.o-header {
	$o-header: &;

	position: absolute;
	top: functions.torem(35px);
	right: 0;
	left: 0;
	z-index: 9;
	width: 100%;

	&__container {
		display: flex;
		flex-wrap: wrap;
	}

	&__left-column {
		width: 100%;
		flex: 0 0 auto;
		@include media-query.breakpoint(smallg) {
			width: 25%;
		}
	}

	&__right-column {
		width: 100%;
		flex: 0 0 auto;
		@include media-query.breakpoint(smallg) {
			width: 75%;
		}
	}

	&__menu {
		margin-top: 0;
		text-align: left;
		@include media-query.breakpoint(smallg) {
			width: 100%;
			text-align: right;
		}
	}

	&__navbar {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: flex-start;
		flex-direction: row;
		flex-wrap: wrap;
		padding: 0 functions.torem(17px);
		@include media-query.breakpoint(smallg) {
			display: inline-flex;
			width: auto;
			justify-content: center;
			align-items: center;
			padding: 0;
		}
	}

	&__overlay {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9;
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .7);
		opacity: 0;
		transition: all .45s ease-in-out;
		pointer-events: none;
		visibility: hidden;

		&.menu-active {
			opacity: 1;
			visibility: visible;
		}
	}

	&__menuitem {
		display: flex;
		width: 100%;
		margin: functions.torem(15px) 0;
		@include media-query.breakpoint(smallg) {
			display: inline-flex;
			width: auto;
			margin: 0 0 0 functions.torem(20px);
		}

		// &:nth-last-child( 1 ) {
		// 	width: 48%;
		// 	@include media-query.breakpoint(smallg) {
		// 		width: auto;
		// 	}
		// }

		// &:nth-last-child( 2 ) {
		// 	width: 48%;
		// 	@include media-query.breakpoint(smallg) {
		// 		width: auto;
		// 	}
		// }
	}

	&__menuanchor {
		color: colors.$black;
		text-decoration: none;
		@include fonts.font-menu-anchor--size;

		&:hover {
			color: colors.$primary-color;
		}

		&--active {
			color: colors.$primary-color;
		}

		&--menusolid {
			display: flex;
			width: 100%;
			max-width: 100%;
			height: functions.torem(51px);
			justify-content: center;
			align-items: center;
			color: colors.$white;
			font-size: functions.torem(14px);
			@include media-query.breakpoint(smallg) {
				width: functions.torem(108px);
				max-width: functions.torem(108px);
			}
			@include media-query.breakpoint(ipad) {
				width: functions.torem(168px);
				max-width: functions.torem(168px);
			}
		}

		&--menuoutline {
			display: flex;
			width: 100%;
			max-width: 100%;
			height: functions.torem(51px);
			justify-content: center;
			align-items: center;
			color: colors.$primary-color;
			font-size: functions.torem(14px);
			@include media-query.breakpoint(smallg) {
				width: functions.torem(108px);
				max-width: functions.torem(108px);
			}
			@include media-query.breakpoint(ipad) {
				width: functions.torem(114px);
				max-width: functions.torem(114px);
			}

			&:hover {
				color: colors.$white;
			}
		}
	}

	&__mobile-menu {
		position: relative;
		display: block;
		padding: functions.torem(15px);
		@include media-query.breakpoint(smallg) {
			display: none;
		}
	}

	&__hamburg {
		position: absolute;
		top: 7px;
		right: 20px;
		display: block;
		@include media-query.breakpoint(smallg) {
			display: none;
		}
	}

	&__menu-area {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;
		width: 350px;
		max-width: 85%;
		height: 100%;
		background: colors.$white;
		background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(218, 231, 254) 100%);
		transition: all .4s ease-in-out;
		overflow: hidden;
		transform: translate(-100%);
		@include media-query.breakpoint(smallg) {
			position: relative;
			transform: none;
			width: 100%;
			max-width: 100%;
			height: auto;
			background: transparent;
		}

		&.menu-active {
			transform: translate(0%);
		}
	}

	&__mobile-close-icon {
		position: absolute;
		top: 50%;
		right: functions.torem(15px);
		transform: translateY(-50%);
	}
}
