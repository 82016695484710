@use "../abstracts/colors";
@use "../abstracts/functions";
@use "../abstracts/media-query";
@use "../abstracts/spacing";
@use "../abstracts/fonts";
@use "../abstracts/anchors";
@use "../abstracts/shadows";
@use "../abstracts/buttons";
@use "../abstracts/z-index";
@use "../abstracts/animations";

// BANNER SECTION STYLE
.o-banner {
	$o-banner: &;

	width: 100%;
	background: rgb(255, 255, 255);
	background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 55%, rgba(218, 231, 254, 1) 100%);

	&__spacing {
		position: relative;
		padding: 0;
		background: url(../../assets/images/element/banner-section-background-blue.svg) no-repeat center;
		background-size: 100% 100%;
	}

	&__splide-progress-bar {
		background: colors.$primary-color;
	}

	&__container {
		display: flex;
		flex-direction: column-reverse;
		flex-wrap: wrap;
		padding-top: functions.torem(100px);
		padding-bottom: functions.torem(20px);
		@include media-query.breakpoint(smallg) {
			align-items: center;
			flex-direction: row;
		}
		@include media-query.breakpoint(ipad) {
			padding-top: functions.torem(70px);
		}

		&--lowsacing {
			padding-top: functions.torem(100px);
			padding-bottom: functions.torem(20px);
			@include media-query.breakpoint(ipad) {
				padding-top: functions.torem(30px);
			}
		}
	}

	&__half-column {
		width: 100%;
		flex: 0 0 auto;
		@include media-query.breakpoint(smallg) {
			width: 50%;
		}
	}

	&__left-column {
		width: 100%;
		flex: 0 0 auto;
		@include media-query.breakpoint(smallg) {
			width: 41.66666667%;
		}
	}

	&__right-column {
		width: 100%;
		flex: 0 0 auto;
		@include media-query.breakpoint(smallg) {
			width: 58.33333333%;
		}
	}

	&__textarea {
		position: relative;
		z-index: z-index.$on-top;
		text-align: left;
	}

	&__heading {
		margin-bottom: functions.torem(30px);
		color: colors.$body-color;
	}

	&__headingsub {
		display: inline-block;
		color: colors.$primary-color;
	}

	&__imagewrap {
		position: relative;
		max-width: functions.torem(570px);
		text-align: center;

		&--full {
			max-width: 100%;
		}
	}

	&__imagemain {
		position: relative;
		//left: functions.torem(-12px);
		z-index: z-index.$on-top;
		width: 100%;
		//width: functions.torem(300px);
		// top: 486px;
		height: auto;
		@include media-query.breakpoint(smallg) {
			left: inherit;
			width: auto;
		}

		&--banner2 {
			width: 100%;
			max-width: functions.torem(593px);
			height: functions.torem(420px);
			margin-bottom: functions.torem(50px);
			@include media-query.breakpoint(ipad) {
				height: functions.torem(586px);
				margin-bottom: 0;
			}
		}
	}

	// banner 2
	&__banner2ai {
		position: absolute;
		top: 0;
		right: 0;
		width: 80%;
		@include media-query.breakpoint(ipad) {
			right: functions.torem(-40px);
			width: auto;
		}
	}

	&__banner2mobile {
		position: absolute;
		top: 0;
		left: 0;
		width: 80%;
		@include media-query.breakpoint(ipad) {
			top: inherit;
			bottom: 0;
			width: auto;
		}
	}

	&__banner2girl {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		width: 55%;
		margin: auto;
		@include media-query.breakpoint(ipad) {
			width: auto;
		}
	}

	&__banner2mainback {
		position: absolute;
		top: -40%;
		left: -20%;
		max-width: 200%;
	}

	&__banner2topglow {
		position: absolute;
		top: 26%;
		left: 15%;
	}

	&__banner2topcircle {
		position: absolute;
		right: 0;
		bottom: functions.torem(36px);
		left: 32%;
		margin: auto;
	}

	&__floatingimage {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__floatingmedicin {
		position: absolute;
		top: 14%;
		left: 6%;
		z-index: z-index.$takeover;
		width: 40px;
		@include media-query.breakpoint(smallg) {
			left: 10%;
			width: auto;
		}
	}

	&__floatinghello {
		position: absolute;
		top: functions.torem(90px);
		right: 0;
		z-index: z-index.$on-top;
		width: functions.torem(100px);
		@include media-query.breakpoint(smallg) {
			right: functions.torem(-30px);
			width: auto;
		}
	}

	&__floatingsyringes {
		position: absolute;
		top: 50%;
		right: functions.torem(-15px);
		transform: translateY(-50%);
		z-index: z-index.$on-top;
		width: functions.torem(120px);
		@include media-query.breakpoint(smallg) {
			top: 45%;
			right: functions.torem(-80px);
			width: auto;
		}
	}

	&__floatingaudio {
		position: absolute;
		bottom: functions.torem(60px);
		left: functions.torem(24px);
		z-index: z-index.$overlay;
		width: functions.torem(176px);
		@include media-query.breakpoint(smallg) {
			bottom: functions.torem(100px);
			left: functions.torem(60px);
			width: auto;
		}
	}

	&__floatingplay {
		position: absolute;
		top: 62%;
		left: functions.torem(80px);
		z-index: z-index.$modal;
	}

	&__floatingbooking {
		position: absolute;
		top: 14%;
		right: 0;
		z-index: z-index.$takeover;
		width: functions.torem(130px);
		@include media-query.breakpoint(smallg) {
			right: 10%;
		}
		@include media-query.breakpoint(medium) {
			top: 33%;
			width: auto;
		}
	}

	&__ellipse1 {
		position: absolute;
		top: 32%;
		right: 18%;
		z-index: z-index.$header;
		width: 15px;
	}

	&__ellipse2 {
		position: absolute;
		top: 30%;
		left: 14%;
		z-index: z-index.$header;
	}

	&__ellipse3 {
		position: absolute;
		top: 27%;
		right: -27px;
		z-index: z-index.$header;
	}

	&__ellipse4 {
		position: absolute;
		bottom: 22%;
		left: 40px;
		z-index: z-index.$header;
	}

	&__ellipseg1 {
		position: absolute;
		top: 35%;
		left: 64%;
		z-index: z-index.$header;
	}

	&__ellipseg2 {
		position: absolute;
		right: 0;
		bottom: functions.torem(20px);
		left: 40%;
		z-index: z-index.$modal;
		width: functions.torem(12px);
		margin: auto;
	}

	&__floatingpink {
		position: absolute;
		top: 48%;
		right: 0;
		z-index: z-index.$takeover;
		opacity: .8;
	}

	&__ellipseb1 {
		position: absolute;
		top: functions.torem(60px);
		left: 56%;
		z-index: z-index.$takeover;
	}

	&__ellipseb2 {
		position: absolute;
		top: functions.torem(60px);
		right: 0;
		z-index: z-index.$header;
		width: functions.torem(15px);
	}

	&__ellipseb3 {
		position: absolute;
		bottom: functions.torem(50px);
		left: 20%;
		z-index: z-index.$header;
		width: 15px;
	}

	&__ellipseb4 {
		position: absolute;
		bottom: functions.torem(20px);
		left: 20%;
		z-index: z-index.$header;
		width: functions.torem(10px);
	}

	&__ellipseb5 {
		position: absolute;
		right: 0;
		bottom: functions.torem(80px);
		z-index: z-index.$header;
	}

	&__ellipseb6 {
		position: absolute;
		top: 0;
		left: 0;
		z-index: z-index.$header;
	}

	&__setimage {
		position: relative;

		&--banner3 {
			width: 100%;
			max-width: functions.torem(723px);
			height: functions.torem(420px);
			@include media-query.breakpoint(ipad) {
				width: 90%;
				height: functions.torem(546px);
			}
		}
	}

	// banner 3
	&__banner3backimage {
		position: absolute;
		top: 0;
		left: 0;
	}

	&__banner3topgirl {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		margin: auto;
	}

	&__backpink {
		position: absolute;
		top: functions.torem(-32px);
		left: -33%;
		max-width: 140%;
		opacity: .8;
		@include media-query.breakpoint(smallg) {
			top: functions.torem(-80px);
			left: functions.torem(-230px);
		}

		&--girl {
			top: inherit;
			bottom: 0;
			left: 0;
			z-index: z-index.$header;
			max-width: 100%;
			@include media-query.breakpoint(smallg) {
				left: 30%;
			}
			@include media-query.breakpoint(ipad) {
				bottom: -25%;
				left: 0;
			}
		}
	}

	&__backrightblue {
		position: absolute;
		top: 50%;
		left: -80%;
	}

	&__dblock {
		@include media-query.breakpoint(smallg) {
			display: block;
		}
	}

	&__loweritalic {
		text-transform: fonts.$lowercase;
		font-style: italic;
	}

	.splide__slide {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
