@use "../abstracts/colors";
@use "../abstracts/functions";
@use "../abstracts/media-query";
@use "../abstracts/spacing";
@use "../abstracts/fonts";
@use "../abstracts/anchors";
@use "../abstracts/shadows";
@use "../abstracts/buttons";
@use "../abstracts/z-index";
@use "../abstracts/animations";
@use "../abstracts/border-radius";

.o-general {
	$o-general: &;

	background: rgb(238, 239, 249);
	background: linear-gradient(0deg, rgb(238, 239, 249) 0%, rgba(201, 198, 236, .48) 50%, rgb(218, 229, 253) 100%);
    
	&__spacing {
		padding: functions.torem(150px) 0 functions.torem(20px);
	}

	&__heading {
		@include fonts.font-size--top-heading;
		margin-bottom: functions.torem(32px);
		text-align: center;
		text-transform: fonts.$uppercase;
		@include media-query.breakpoint(smallg) {
			margin-bottom: functions.torem(72px);
		}
	}

	&__title {
		@include fonts.font-size--tabtitle;
		margin-bottom: functions.torem(15px);
		text-transform: fonts.$uppercase;
	}

	&__content {
		@include fonts.font-size--itemtitle1;
		margin-bottom: functions.torem(30px);
		color: colors.$black;
	}
}
