@use "../abstracts/colors";
@use "../abstracts/functions";
@use "../abstracts/media-query";
@use "../abstracts/spacing";
@use "../abstracts/fonts";
@use "../abstracts/anchors";
@use "../abstracts/shadows";
@use "../abstracts/buttons";
@use "../abstracts/z-index";
@use "../abstracts/animations";
@use "../abstracts/border-radius";

.o-contact {
	$o-contact: &;

	background: rgb(238, 239, 249);
	background: linear-gradient(0deg, rgba(238, 239, 249, 1) 0%, rgba(201, 198, 236, .48) 50%, rgba(218, 229, 253, 1) 100%);

	&__spacing {
		padding: functions.torem(110px) 0 functions.torem(20px);
		@include media-query.breakpoint(smallg) {
			padding: functions.torem(120px) 0 functions.torem(20px);
		}
		@include media-query.breakpoint(ipad) {
			padding: functions.torem(140px) 0 functions.torem(20px);
		}
	}

	&__heading {
		@include fonts.font-size--top-heading;
		margin-bottom: functions.torem(32px);
		text-align: center;
		text-transform: fonts.$uppercase;
		@include media-query.breakpoint(smallg) {
			margin-bottom: functions.torem(72px);
		}
	}

	&__wrapper {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column-reverse;
		flex-wrap: wrap;
		@include media-query.breakpoint(smallg) {
			flex-direction: row;
		}
		@include media-query.breakpoint(ipad) {
			justify-content: flex-end;
			flex-wrap: nowrap;
		}
	}

	&__imagearea {
		position: relative;
		display: flex;
		width: 100%;
		max-width: functions.torem(374px);
		height: functions.torem(398px);
		justify-content: center;
		align-items: flex-end;
		margin-top: functions.torem(70px);
		@include media-query.breakpoint(smallg) {
			margin-top: 0;
		}
		@include media-query.breakpoint(ipad) {
			position: absolute;
			top: functions.torem(-70px);
			left: functions.torem(18px);
		}
	}

	&__imagemain {
		position: relative;
		z-index: z-index.$on-top;
	}

	&__imagebackground {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__outer1 {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		margin: auto;
	}

	&__outer2 {
		position: absolute;
		top: functions.torem(24px);
		right: 0;
		left: 0;
		width: 85%;
		margin: auto;
		@include media-query.breakpoint(smallg) {
			width: auto;
		}
	}

	&__topback {
		position: absolute;
		top: -50%;
		right: 0;
	}

	&__imagewhite {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	&__white1 {
		position: absolute;
		top: functions.torem(20px);
		left: 20%;
		width: functions.torem(20px);
		height: functions.torem(20px);
		background-color: rgba(252, 252, 252, .57);
		border-radius: border-radius.$border-radius--full-round;
		filter: blur(3px);
	}

	&__white2 {
		position: absolute;
		top: functions.torem(15px);
		left: 70%;
		width: functions.torem(18px);
		height: functions.torem(18px);
		background-color: rgba(252, 252, 252, .57);
		border-radius: border-radius.$border-radius--full-round;
		filter: blur(3px);
	}

	&__white3 {
		position: absolute;
		top: functions.torem(10px);
		left: 90%;
		width: functions.torem(10px);
		height: functions.torem(10px);
		background-color: rgba(252, 252, 252, .57);
		border-radius: border-radius.$border-radius--full-round;
		filter: blur(3px);
	}

	&__address {
		position: relative;
		display: flex;
		max-width: functions.torem(816px);
		justify-content: center;
		align-items: center;
		flex-direction: column-reverse;
		flex-wrap: wrap;
		padding: functions.torem(20px);
		background-color: colors.$white;
		border-radius: border-radius.$border-radius--sbtn;
		@include media-query.breakpoint(smallg) {
			flex-direction: row;
			border-radius: border-radius.$border-radius--box-30;
		}
		@include media-query.breakpoint(ipad) {
			justify-content: space-between;
			flex-wrap: nowrap;
			padding: functions.torem(20px) functions.torem(19px) functions.torem(16px) functions.torem(70px);
		}
	}

	&__leftaddress {
		width: 100%;
		max-width: functions.torem(335px);
		@include media-query.breakpoint(smallg) {
			width: 45%;
		}
	}

	&__rightaddress {
		width: 100%;
		max-width: functions.torem(380px);
		margin-bottom: functions.torem(30px);
		padding: functions.torem(25px);
		background-color: colors.$service-block-color2;
		border-radius: border-radius.$border-radius--sbtn;
		@include media-query.breakpoint(smallg) {
			width: 49%;
			margin-bottom: 0;
			padding: functions.torem(48px) functions.torem(40px) functions.torem(35px);
			border-radius: border-radius.$border-radius--box-30;
		}
		@include media-query.breakpoint(ipad) {
			width: 50%;
		}
	}

	&__title {
		@include fonts.font-size--tabtitle;
		margin-bottom: functions.torem(12px);
		color: colors.$black;
		text-transform: fonts.$uppercase;

		&--mb20 {
			margin-bottom: functions.torem(20px); 
		}
	}

	&__content {
		@include fonts.font-size--itemtitle1;
		margin-bottom: functions.torem(30px);
		color: colors.$black;
	}

	&__label {
		@include fonts.font-size--label;
		margin-bottom: functions.torem(9px);
		color: colors.$black;
	}

	&__link {
		@include fonts.font-size--text;
		color: colors.$link-color;

		&:hover {
			text-decoration: underline;
		}
	}

	&__input {
		height: functions.torem(64px);
		margin-bottom: functions.torem(20px);
		font-size: functions.torem(14px);
		border-radius: border-radius.$border-radius--sbtn;

		&--textarea {
			width: 100%;
			height: functions.torem(112px);
			padding: functions.torem(22px);
			resize: none;
		}
	}

	&__btn {
		position: relative;
		width: 100%;
		justify-content: center;
		margin: functions.torem(15px) auto 0;
		padding: functions.torem(12px);
		cursor: pointer;
		text-indent: functions.torem(-10px);

		.btn-default__arrow {
			position: absolute;
			top: functions.torem(5px);
			right: functions.torem(6px);
		}
	}
}

.formgroup-section {
	position: relative;

	.error-message {
		position: absolute;
		bottom: 5px;
		left: 0;
		width: 100%;
		color: rgb(245, 0, 0);
		font-size: 11px;
		line-height: 11px;
	}
}

.form-error-message {
	margin-bottom: 10px;
	padding: 10px;
	color: #FFF;
	font-size: 12px;
	line-height: 15px;
	background-color: #FF5F5F;
	border-radius: 5px;
}

.form-success-message {
	margin-bottom: 10px;
	padding: 10px;
	color: #FFF;
	font-size: 12px;
	line-height: 15px;
	background-color: #159E00;
	border-radius: 5px;
}
