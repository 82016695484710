@use "../abstracts/colors";
@use "../abstracts/functions";
@use "../abstracts/media-query";
@use "../abstracts/spacing";
@use "../abstracts/fonts";
@use "../abstracts/anchors";
@use "../abstracts/shadows";
@use "../abstracts/buttons";
@use "../abstracts/z-index";
@use "../abstracts/animations";

.o-login {
	$o-login: &;

	height: auto;
	min-height: 100vh;
	background: rgb(255, 255, 255);
	background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(218, 231, 254, 1) 100%);
	@include media-query.breakpoint(elarge) {
		height: 100vh;
		overflow: hidden;
	}

	&__logo {
		display: inline-block;
	}

	&__boxarea {
		position: relative;
		z-index: 1;
		width: 100%;
		height: fit-content;
		min-height: 100vh;
		padding: functions.torem(80px) functions.torem(20px) functions.torem(20px);
		overflow: hidden;
		@include media-query.breakpoint(smallg) {
			padding: functions.torem(110px) functions.torem(20px) functions.torem(50px);
		}
	}

	&__flexrow {
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}

	&__leftpart {
		width: 100%;
		flex: 0 0 auto;
		@include media-query.breakpoint(smallg) {
			width: 33.33333333%;
		}
	}

	&__rightpart {
		width: 100%;
		flex: 0 0 auto;
		@include media-query.breakpoint(smallg) {
			width: 66.66666667%;
		}
	}

	&__leftimage {
		position: relative;
		float: right;
		display: none;
		@include media-query.breakpoint(smallg) {
			display: block;
		}
		@include media-query.breakpoint(xbig) {
			top: functions.torem(120px);
		}

		&--signup {
			top: inherit;
		}
	}

	&__leftimagemain {
		position: relative;
	}

	&__leftimageblue {
		position: absolute;
		top: functions.torem(-20px);
		right: functions.torem(-40px);
	}

	&__leftimagepink {
		position: absolute;
		bottom: functions.torem(-20px);
		z-index: z-index.$on-top;
	}

	&__rightbox {
		position: relative;
		width: 100%;
		max-width: functions.torem(786px);
		padding: functions.torem(15px);
		background-color: rgb(239 244 251);
		border-radius: functions.torem(20px);
		@include media-query.breakpoint(smallg) {
			padding: functions.torem(22px);
		}

		&--shortbox {
			text-align: center;
			@include media-query.breakpoint(smallg) {
				max-width: functions.torem(518px);
				padding: functions.torem(40px) functions.torem(20px);
			}
		}
	}

	&__rightboxpink {
		position: absolute;
		top: functions.torem(-90px);
		left: functions.torem(-120px);
		display: none;
		opacity: .12;
		@include media-query.breakpoint(smallg) {
			display: block;
		}
	}

	&__rightboxblue {
		position: absolute;
		bottom: -70%;
		left: -40%;
		display: none;
		@include media-query.breakpoint(smallg) {
			display: block;
		}
	}

	&__rightboxtittle {
		margin-bottom: functions.torem(20px);
		color: rgb(3 60 129);
		font-size: functions.torem(20px);
		font-weight: fonts.$font-weight--normal;
		line-height: normal;
		text-transform: uppercase;
		@include media-query.breakpoint(smallg) {
			margin-bottom: functions.torem(24px);
			margin-bottom: functions.torem(10px);
		}
		@include media-query.breakpoint(extralarge) {
			margin-bottom: functions.torem(15px);
			font-size: functions.torem(32px);
			line-height: functions.torem(44px);
		}
	}

	&__rightboxdubtittle {
		margin: functions.torem(20px) 0;
		color: colors.$black;
		font-size: functions.torem(14px);
		font-weight: fonts.$font-weight--medium;
		@include media-query.breakpoint(smallg) {
			margin: functions.torem(30px) 0 functions.torem(20px);
		}
	}

	&__radioarea {
		display: flex;
		margin-bottom: functions.torem(15px);
	}

	&__radiobox {
		position: relative;
		display: flex;
		width: auto;
		margin-right: functions.torem(10px);
		padding: functions.torem(10px) functions.torem(10px) functions.torem(10px) functions.torem(30px);
		border-radius: functions.torem(10px);
		cursor: pointer;

		&:hover {
			background: colors.$border-color1;
		}
		@include media-query.breakpoint(smallg) {
			width: 48%;
			max-width: functions.torem(236px);
			padding: functions.torem(12px) functions.torem(10px) functions.torem(12px) functions.torem(50px);
		}

		.border {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 0;

			&::before {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				border: functions.torem(1px) solid colors.$border-color1;
				border-radius: functions.torem(10px);
			}
		}

		.checkmark {
			position: absolute;
			top: functions.torem(6px);
			left: functions.torem(7px);
			width: functions.torem(16px);
			height: functions.torem(16px);
			background-color: transparent;
			border: functions.torem(1px) solid colors.$border-color2;
			border-radius: 50%;
	
			svg {
				position: absolute;
				top: 50%;
				right: 0;
				left: 0;
				display: none;
				margin: auto;
				transform: translateY(-50%);
			}
		}
	}

	&__radioinput {
		position: absolute;
		opacity: 0;
		cursor: pointer;

		&:checked {
			~ .checkmark {
				background-color: colors.$primary-color;
				border-color: colors.$primary-color;

				svg {
					display: block;
				}
			}

			~ .border {
				&::before {
					border: 1px solid colors.$border-color2;
				}
			}   
		}
	}

	&__radioboximg {
		margin-right: functions.torem(10px);
		@include media-query.breakpoint(smallg) {
			margin-right: functions.torem(13px);
		}
	}

	&__radioboxtext {
		color: colors.$black;
		font-family: fonts.$font-family--body;
		font-size: functions.torem(12px);
		line-height: normal;
		@include media-query.breakpoint(smallg) {
			font-size: functions.torem(16px);
			line-height: functions.torem(21px);
		}
	}

	&__formgroup {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		gap: 0;
		margin-bottom: functions.torem(10px);
		@include media-query.breakpoint(smallg) {
			gap: functions.torem(10px);
			margin: 0;
		}
	}

	&__form-controls {
		width: 100%;
		height: functions.torem(52px);
		margin-bottom: functions.torem(10px);
		padding: functions.torem(8px) functions.torem(16px);
		color: rgb(85, 85, 85);
		font-family: fonts.$font-family--body;
		font-size: functions.torem(14px);
		line-height: functions.torem(21px);
		background-color: colors.$white;
		border: 0;
		border-radius: functions.torem(10px);
		@include media-query.breakpoint(smallg) {
			width: 49%;
		}

		&--full {
			@include media-query.breakpoint(smallg) {
				width: 100%;
			}
		}

		&--select {
			appearance: none;
			background: url(../images/icons/select-arrow.svg) 92% / 15% no-repeat colors.$white;
			background-size: functions.torem(14px);
		}

		&--textarea {
			width: 100%;
			height: functions.torem(82px);
			resize: none;
			padding: functions.torem(10px);
		}
	}

	&__password {
		position: relative;
		width: 100%;
		@include media-query.breakpoint(smallg) {
			width: 49%;
		}

		&--full {
			@include media-query.breakpoint(smallg) {
				width: 100%;
			}	
		}
		#{$o-login}__form-controls {
			width: 100%;
			-webkit-text-security: disc;
		}
	}

	&__eye {
		position: absolute;
		top: 42%;
		right: functions.torem(20px);
		z-index: z-index.$on-top;
		transform: translateY(-50%);
		@include media-query.breakpoint(smallg) {
			top: 50%;
		}

		.hide {
			display: block;
		}

		.show {
			display: none;
		}
	}

	.showPass {
		.hide {
			display: none;
		}

		.show {
			display: block;
		}
		#{$o-login}__form-controls {
			-webkit-text-security: none;
		}
	}

	&__bottomtext {
		margin-top: functions.torem(24px);
		color: colors.$black;
		font-family: fonts.$font-family--body;
		font-size: functions.torem(16px);
		line-height: functions.torem(24px);
		text-align: center;
		@include media-query.breakpoint(smallg) {
			margin-top: functions.torem(10px);
			text-align: left;
		}
		@include media-query.breakpoint(extralarge) {
			margin-top: functions.torem(24px);
		}

		&--topmargin {
			margin-top: functions.torem(30px);
			font-size: functions.torem(15px);
			text-align: center;
			@include media-query.breakpoint(smallg) {
				margin-top: functions.torem(30px);
			}
			@include media-query.breakpoint(extralarge) {
				margin-top: functions.torem(30px);
			}
		}
	}

	.btn-default {
		display: block;
		margin: auto;
		cursor: pointer;
		@include media-query.breakpoint(smallg) {
			margin: 0;
		}

		&--topmargin {
			@include media-query.breakpoint(smallg) {
				margin: functions.torem(10px) 0 0;
			}
			@include media-query.breakpoint(extralarge) {
				margin: functions.torem(20px) 0 0;
			}
		}

		&--auto {
			margin: functions.torem(10px) auto 0;
			@include media-query.breakpoint(extralarge) {
				margin: functions.torem(20px) auto 0;
			}
		}
	}

	&__bottomlink {
		color: colors.$primary-color;
		text-decoration: none;

		&:hover {
			color: rgb(3, 60, 129);
		}
	}

	&__forgotpassword {
		display: block;
		width: 100%;
		text-align: right;
	}

	&__forgotanchor {
		color: colors.$primary-color;
		font-size: functions.torem(12px);

		&:hover {
			color: rgb(3, 60, 129);
			text-decoration: underline;
		}
	}
}
