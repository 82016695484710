@use "../abstracts/media-query";
@use "../abstracts/functions";
@use "../abstracts/colors";
@use "../abstracts/fonts";
@use "../abstracts/z-index";
@use "../abstracts/border-radius";

.o-footer {
	$o-footer: &;

	background: rgb(255, 255, 255);
	background: linear-gradient(0deg, rgba(195, 213, 233, .7) 0%, rgba(255, 255, 255, 1) 100%);
	@include media-query.breakpoint(smallg) {
		background: url(../../assets/images/element/banner-section-background-blue.svg) no-repeat center;
		background-size: 100% 100%;
	}

	&--contact {
		background: rgba(238, 239, 249, 1);
		@include media-query.breakpoint(smallg) {
			background: rgba(238, 239, 249, 1);
		}
		#{o-footer}__spacing {
			padding: functions.torem(30px) 0;
			@include media-query.breakpoint(smallg) {
				padding: functions.torem(100px) 0;
			}
		}
	}

	&__spacing {
		padding: functions.torem(100px) 0 functions.torem(30px);
		@include media-query.breakpoint(smallg) {
			padding: functions.torem(100px) 0;
		}
	}

	&__top {
		position: relative;
	}

	&__topimage {
		position: relative;
		max-width: functions.torem(500px);
		margin: auto;
		text-align: center;
	}

	&__tophuman {
		position: relative;
		top: functions.torem(20px);
		left: 8%;
		max-width: functions.torem(250px);
		@include media-query.breakpoint(smallg) {
			left: 24%;
		}
	}

	&__topbackpink {
		position: absolute;
		top: -40%;
		right: 0;
		left: 0;
		margin: auto;
		opacity: .5;
	}

	&__largeshape {
		position: absolute;
		top: functions.torem(140px);
		right: 0;
		left: functions.torem(15px);
		width: functions.torem(200px);
		margin: auto;
	}

	&__mshape {
		position: absolute;
		top: 0;
		left: functions.torem(-30px);
		width: functions.torem(140px);
	}

	&__smshape {
		position: absolute;
		width: functions.torem(26px);

		&--one {
			top: 10%;
			left: 30%;
		}

		&--two {
			top: 25%;
			right: 26%;
		}
	}

	&__sxshape {
		position: absolute;
		top: 40%;
		left: 30%;
		width: functions.torem(14px);
	}

	&__pinkcircle {
		position: absolute;
		top: 50%;
		right: 15%;
	}

	&__pinkcircle1 {
		position: absolute;
		bottom: functions.torem(-18px);
		left: 20%;
		width: functions.torem(160px);
		opacity: .6;
	}

	&__footerwave {
		position: absolute;
		right: 0;
		bottom: functions.torem(-5px);
		left: 0;
		z-index: z-index.$on-top;
		margin: auto;
		@include media-query.breakpoint(smallg) {
			bottom: functions.torem(-80px);
		}
	}

	&__bottom {
		position: relative;
		z-index: z-index.$on-top;
		background-color: transparent;
		@include media-query.breakpoint(smallg) {
			padding: functions.torem(25px) functions.torem(40px);
			background-color: colors.$footer-bg;
			border-radius: border-radius.$border-radius--box;
		}
	}

	&__bottomtop {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	&__bottomlogo {
		max-width: functions.torem(190px);
		margin: auto;
		@include media-query.breakpoint(smallg) {
			margin: 0;
		}
	}

	&__bottomlogo-img {
		width: functions.torem(155px);
		height: auto;
	}

	&__bottommenu {
		display: none;
		@include media-query.breakpoint(smallg) {
			display: flex;
		}
	}

	&__menuitem {
		margin-left: functions.torem(40px);
	}

	&__menuitems {
		@include fonts.font-menu-anchor--size;
		color: colors.$black;

		&:hover {
			color: colors.$primary-color;
		}  
	}

	&__bottomdown {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		margin-top: functions.torem(20px);
		text-align: center;
		@include media-query.breakpoint(smallg) {
			flex-direction: row;
			margin-top: functions.torem(35px);
			text-align: left;
		}	
	}

	&__bottomcontent {
		@include fonts.font-size--small;
		color: colors.$body-color;

		&--link {
			width: 50%;
			margin-top: functions.torem(15px);
			@include media-query.breakpoint(smallg) {
				display: block;
				width: auto;
				margin-top: 0;
				margin-left: functions.torem(30px);
			}

			&:hover {
				color: colors.$primary-color;
			}
		}

		&--country {
			display: none;
			text-align: center;
			text-transform: fonts.$uppercase;
			@include media-query.breakpoint(smallg) {
				display: none;
			}
		}
	}

	&__bottomdownright {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		flex-wrap: wrap;
		@include media-query.breakpoint(smallg) {
			width: 70%;
			justify-content: flex-end;
			flex-wrap: nowrap;
		}
	}

	&__sociallist {
		display: flex;
		width: 100%;
		justify-content: center;
		margin-top: functions.torem(14px);
		@include media-query.breakpoint(smallg) {
			width: 30%;
			justify-content: flex-end;
			margin-top: 0;
			margin-left: functions.torem(20px);
		}
	}

	&__socialitem {
		margin-right: functions.torem(5px);
		margin-left: functions.torem(5px);
		@include media-query.breakpoint(smallg) {
			margin-right: 0;
			margin-left: functions.torem(10px);
		}
	}

	&__socialitems {
		&:hover {
			img {
				filter: invert(.7) sepia(1) saturate(5) hue-rotate(250deg);
			}
		}
	}
}
