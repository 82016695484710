@use "../abstracts/colors";
@use "../abstracts/functions";
@use "../abstracts/media-query";
@use "../abstracts/spacing";
@use "../abstracts/fonts";
@use "../abstracts/anchors";
@use "../abstracts/shadows";
@use "../abstracts/buttons";
@use "../abstracts/z-index";
@use "../abstracts/border-radius";

.o-faq {
	$o-faq: &;

	position: relative;
	overflow: hidden;
	background: rgb(255, 255, 255);
	background: linear-gradient(0deg, rgba(201, 198, 236, .3) 0%, rgba(243, 243, 252, 1) 100%);

	&--visible {
		overflow: visible;
	}
	@include media-query.breakpoint(smallg) {
		background: transparent;
	}

	&__title {
		margin-bottom: functions.torem(18px);
		text-align: center;
		@include media-query.breakpoint(smallg) {
			margin-bottom: functions.torem(60px);
		}
	}

	&__spacing {
		padding: functions.torem(50px) 0;
		@include media-query.breakpoint(smallg) {
			padding: functions.torem(80px) 0;
		}
	}

	&__background {
		position: absolute;
		top: 0;
		left: 0;
		display: none;
		width: 100%;
		height: 100%;
		@include media-query.breakpoint(medium) {
			display: block;
		}
	}

	&__topfaq {
		position: absolute;
		top: -30%;
		left: 0;
		display: none;
		width: 100%;
		@include media-query.breakpoint(medium) {
			display: block;
		}
	}

	&__rightfaq {
		position: absolute;
		top: 50%;
		right: -20%;
		transform: translateY(-50%);
		display: none;
		@include media-query.breakpoint(medium) {
			display: block;
		}
	}

	&__shape {
		position: absolute;
		top: 30%;
		right: 0;
		z-index: z-index.$on-top;
		display: none;
		width: functions.torem(235px);
		@include media-query.breakpoint(medium) {
			display: block;
		}
	}

	&__shape1 {
		position: absolute;
		top: -3%;
		left: 0;
		z-index: z-index.$on-top;
		display: none;
		width: functions.torem(235px);
		@include media-query.breakpoint(medium) {
			display: block;
		}
	}
}
