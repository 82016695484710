.o-tab {
	$o-tab: &;

	&__content {
		display: none;
		opacity: 0;
		transition: opacity 2s ease-in-out;

		&.active {
			opacity: 1;
		}
	}

	&__button {
		background: transparent;
		cursor: pointer;
	}
}
